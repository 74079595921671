<script setup>
import { ref, onMounted, defineProps } from 'vue'

import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";

const props = defineProps({
    version: {
        type: Number,
        default: 4
    }
})

const data = ref({}) 
const dataLoading = ref(true)
const zoom = 10

async function fetchIp(version) {
    const url = 'https://myipv' + version + '.gecka.nc';
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`Response status: ${response.status}`);
        }

        const json = await response.json();
        return json
    } catch (error) {
        console.error(error.message);
    }
}


onMounted(() => {
    fetchIp(props.version).then((json) => {
        data.value = json
        dataLoading.value = false
    })
})

</script>
<template>
    <div class="w-full p-6 rounded-2xl bg-gray-200 dark:bg-gray-700 mt-6">
        <dl class="mb-6">
            <dt class="font-bold">
                Mon IP V{{version}} :
            </dt>
            <dd>
                <span v-if="dataLoading">Loading ...</span>
                <span v-else>{{ data.REMOTE_ADDR }}
                <span v-if="data.hostname && data.hostname !== data.REMOTE_ADDR"><br>{{data.hostname}}</span>

                </span>
            </dd>
            <dt class="font-bold mt-4" v-if="!dataLoading">
                Localisation :
            </dt>
            <dd v-if="!dataLoading">
                <span v-if="! data.geoip">N/A</span>
                <span v-else>
                    {{data.geoip.city.name_fr}}, {{data.geoip.country.name_fr}}
                </span>
            </dd>
        </dl>
        <div v-if="!dataLoading && data.geoip" class="w-full"  style="height:200px;">
            <l-map ref="map" v-model:zoom="zoom" :center="[data.geoip.location.latitude, data.geoip.location.longitude]">
      <l-tile-layer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        layer-type="base"
        name="OpenStreetMap"
      ></l-tile-layer>
      <l-marker :lat-lng="[data.geoip.location.latitude, data.geoip.location.longitude]"></l-marker>
    </l-map>

        </div>
    </div>
</template>