<script setup>
import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";
import {ref} from 'vue'

const browser = ref(window.navigator.userAgent)
const canLocalize = ref("geolocation" in navigator)
const localized = ref(false)
const location = ref(false)
const zoom = 18

function getLocation() {
    navigator.geolocation.getCurrentPosition((position) => {
        console.log(position)
        location.value= [position.coords.latitude, position.coords.longitude];
    });
}

</script>
<template>
<div class="w-full p-6 rounded-2xl bg-gray-200 dark:bg-gray-700 mt-6">
<dl>
    <dt class="font-bold">Navigateur :</dt>
    <dd>{{browser}}</dd>
</dl>
<dl v-if="canLocalize">
    <dt class="font-bold mt-4">Localisation Navigateur :</dt>
    <dd class="text-center"><button v-if="!localized" type="button" 
    class="mt-4 mb-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-sm text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
    @click="getLocation()"
    >Obtenir la localisation</button></dd>
</dl>
<div v-if="location" class="w-full"  style="height:200px;">
            <l-map ref="map" v-model:zoom="zoom" :center="location">
            <l-tile-layer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                layer-type="base"
                name="OpenStreetMap"
            ></l-tile-layer>
            <l-marker :lat-lng="location"></l-marker>
            </l-map>
        </div>
</div>

</template>